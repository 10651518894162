.textbox-icon {
    padding: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 50px 0;

    .icon {
        min-width: 30%;
        flex-basis: 30%;
        padding-top: 30px;
        text-align: center;

        @media (max-width: 520px) {
            display: none;
        }
    }

    .content {
        min-width: 67%;
        width: 67%;
        flex-basis: 67%;

        @media (max-width: 520px) {
            min-width: 100%;
            width: 100%;
            flex-basis: 100%;
        }

        h3 {
            margin-bottom: 20px;
        }
    }
}
