.navbar__nav {
    flex-grow: 2;
    flex-basis: 100%;

    @media (min-width: 1024px) {
        flex-basis: calc(100% - 130px);
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;
    }

    &.is-desktop {
        display: none;

        @media (min-width: 1024px) {
            display: flex;
        }
    }

    &.is-mobile {
        display: flex;
        flex-flow: column nowrap;
        background-color: var(--color-dark-navy);
        overflow: hidden;
        position: fixed;
        top: 80px; // logo + toggle height
        left: 0;
        width: 100%;
        height: calc(100% - 80px);
        z-index: 20;
        overflow-y: scroll;

        @media (min-width: 769px) {
            top: 110px;
            height: calc(100% - 110px);
        }

        @media (min-width: 1024px) {
            display: none;
        }
    }

    .nav__links {
        color: var(--color-white);
        width: 100%;

        @media (min-width: 1024px) {
            color: var(--color-dark-navy);
            text-align: right;
            display: flex;
            flex-flow: row wrap;
            width: auto;
        }

        .link--active {
            font-weight: bold;
        }
    }

    a {
        text-decoration: none;
        line-height: 1.5;
        display: block;
        white-space: nowrap;
        margin-top: 0;

        &.link--admin {
            background-color: var(--color-strava);
            line-height: 80px;
            padding: 0 30px;

            @media (min-width: 1024px) {
                padding: 0;
                background-color: transparent;
                line-height: 1;

                &:hover svg * {
                    stroke: var(--color-strava);
                }
            }
        }
    }

    .link-group {
        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        padding: 0 30px;

        @media (min-width: 1024px) {
            margin: 5px 30px;
            height: auto;
            padding: 0;
        }

        a {
            margin: 0;

            &:last-child {
                @media (min-width: 1024px) {
                    margin: 0 10px 0 15px;
                }
            }
        }
    }

    button {
        outline: 0;
        border: none;
        background: none;
        cursor: pointer;
        margin: 5px 10px;
        padding-top: 0;

        &.langswitch {
            font-family: var(--font-galano);
            display: flex;
            align-items: center;
            color: var(--color-dark-navy);

            span {
                margin-left: 5px;
            }
        }
    }

    &.is-mobile {
        .button {
            background-color: var(--color-fresh-mint);
            margin: 20px;
            padding-top: 18px;
            width: calc(100% - 40px);
            text-align: center;

            &:hover {
                background-color: var(--color-white);
            }

            svg {
                display: none;
            }
        }

        .button--invert {
            background-color: var(--color-dark-navy);
            border-color: var(--color-fresh-mint);

            &:hover {
                background-color: var(--color-white);
                color: var(--color-fresh-mint);
            }
        }

        .link-group {
            a {
                height: 100%;
                line-height: 80px;

                &:first-child {
                    flex-grow: 2;
                }

                &:last-child:not(:first-child) {
                    flex-basis: 40px;
                    text-align: center;
                }
            }
        }

        .langswitch {
            padding: 30px;
            background-color: var(--color-deep-sea-green);
            color: var(--color-white);
            font-size: 16px;
            margin: auto 0 0 0;

            span {
                margin-left: 10px;
            }
        }
    }

    .link__loggedOut {
        @media (max-width: 600px) {
            flex-basis: 100%;
            text-align: right;
        }

        &.link--active {
            font-weight: bold;
        }
    }

    &.is-desktop .button {
        padding-top: 7px;
        padding-bottom: 7px;
        margin-right: 20px;
        margin-top: 0;
        font-size: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}
