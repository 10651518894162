@charset "UTF-8";
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 400;
  src: local("Galano Grotesque"), url("/assets/fonts/Galano-Grotesque.woff2") format("woff2");
  unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8, U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137, U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192, U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117, U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 700;
  src: local("Galano Grotesque Bold"), url("/assets/fonts/Galano-Grotesque-Bold.woff2") format("woff2");
  unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8, U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137, U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192, U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117, U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
@font-face {
  font-family: "Galano Grotesque Alt";
  font-style: normal;
  font-weight: 400;
  src: local("Galano Grotesque Alt"), url("/assets/fonts/Galano-Grotesque-Alt.woff2") format("woff2");
  unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8, U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137, U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192, U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117, U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
@font-face {
  font-family: "Galano Grotesque Alt";
  font-style: normal;
  font-weight: 700;
  src: local("Galano Grotesque Alt Bold"), url("/assets/fonts/Galano-Grotesque-Alt-Bold.woff2") format("woff2");
  unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8, U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137, U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192, U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117, U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
:root {
  --font-galano: "Galano Grotesque", sans-serif;
  --font-galano-alt: "Galano Grotesque Alt", sans-serif;
}

:root {
  --color-dark-navy: #03384d; /* Primary Color 1 */
  --color-smalt-blue: #597489; /* Primary Color Lighter */
  --color-fresh-mint: #5bddb8; /* Secondary Color 1 */
  --color-deep-sea-green: #0d4c5b; /* Secondary Color Darker */
  --color-flamingo-pink: #e3177c; /* Highlight Color 2 */
  --color-mauvelous: #f080b7; /* Highlight Color Lighter */
  --color-black: #000000; /* Neutral Black */
  --color-regent-gray: #929aa0; /* Neutral Black Lighter 1 */
  --color-white: #ffffff; /* Neutral White */
  --color-ultra-light: #f8f8f8; /* Neutral White Darker 1 */
  --color-porcellain: #f2f5f6; /* Neutral White Darker 2 */
  --color-loblolly: #c8d1d7; /* Neutral White Darker 3 */
  --color-alto-gray: #d8d8d8; /* Neutral White Darker 4 */
  --color-geyser: #e1e8e9; /* Neutral White Darker 5 */
  --color-athens-gray: #e5eaed; /* Neutral White Darker 6 */
  --color-strava: #fc4c02; /* Semantic Orange 1 */
  --font-galano: "Galano Grotesque", sans-serif; /* Font HL */
  --font-galano-alt: "Galano Grotesque Alt", sans-serif; /* Font Text */
}

:root {
  --shadow-level-1: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --shadow-level-2: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --shadow-level-3: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 16px 24px 0 rgba(0, 0, 0, 0.1);
  --shadow-level-4: 0 0 2px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
html,
body {
  height: 100%;
}

body {
  font-family: var(--font-galano);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-dark-navy);
  background-color: var(--color-white);
  margin: 0;
  padding: 0;
}
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.button {
  font-family: var(--font-galano);
  font-size: 18px;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;
  padding: 18px 30px;
  background-color: var(--color-fresh-mint);
  color: var(--color-white);
  cursor: pointer;
  font-weight: bold;
  margin-top: 50px;
  outline: 0;
  transition: background-color 0.3s ease-in-out;
  border: 5px solid var(--color-fresh-mint);
}
.button:hover {
  color: var(--color-fresh-mint);
  background-color: var(--color-white);
}
.button--invert {
  background-color: var(--color-dark-navy);
  border-color: var(--color-dark-navy);
}
.button--invert:hover {
  color: var(--color-dark-navy);
  background-color: var(--color-white);
}
.button--strava {
  background-color: var(--color-strava);
  border-color: var(--color-strava);
}
.button--strava:hover {
  color: var(--color-strava);
  background-color: var(--color-white);
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.button--text {
  background: transparent;
  color: var(--color-dark-navy);
  text-decoration: underline;
  outline: 0;
  border: none;
  margin-top: 50px;
  font-family: var(--font-galano);
  cursor: pointer;
}
.button--text:hover {
  color: var(--color-flamingo-pink);
}
.button--circle {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  transform: rotate(16deg);
  background-color: var(--color-strava);
  color: var(--color-white);
  font-size: 26px;
  line-height: 1.3;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  border: 5px solid var(--color-strava);
  transition: all 0.3s ease-in-out;
}
.button--circle:hover {
  color: var(--color-strava);
  background-color: var(--color-white);
  transform: rotate(0deg);
}
@media (max-width: 769px) {
  .button--circle {
    transform: rotate(-16deg);
    right: 150px;
    width: 150px;
    height: 150px;
    font-size: 20px;
  }
}

.btn--connectStrava {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: -2px;
  font-family: var(--font-galano);
  color: var(--color-dark-navy);
  font-size: 16px;
}
.btn--connectStrava img {
  vertical-align: middle;
}

textarea,
input {
  font-size: 14px;
  line-height: 1.2;
  font-family: var(--font-galano-alt);
}
textarea.ng-invalid.ng-dirty,
input.ng-invalid.ng-dirty {
  border-color: var(--color-flamingo-pink) !important;
}
textarea.ng-invalid.ng-dirty + small,
input.ng-invalid.ng-dirty + small {
  color: var(--color-flamingo-pink) !important;
}

input[type=text],
input[type=password],
textarea {
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  min-height: 45px;
  line-height: 1;
  color: var(--color-dark-navy);
  font-weight: bold;
  padding: 0 12px;
  outline: 0;
  width: 100%;
  margin: 0 auto;
}
input[type=text]:focus,
input[type=password]:focus,
textarea:focus {
  border-color: var(--color-fresh-mint);
}

textarea {
  padding: 12px;
  height: 150px;
  line-height: 1.5;
}

a {
  color: inherit;
}

app-landing-page {
  background: var(--color-white);
  display: block;
}

.whitebox {
  background-color: var(--color-white);
  box-shadow: var(--shadow-level-2);
}

.whitebox-padding {
  padding: 80px 80px 40px !important;
}
@media (max-width: 768px) {
  .whitebox-padding {
    padding: 80px 40px 40px !important;
  }
}

.mt50 {
  margin-top: 50px;
}

.toast-top-right {
  top: 120px;
  right: 12px;
}

.toast-error {
  background-color: var(--color-flamingo-pink);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cg fill='%23ffffff' fill-rule='nonzero'%3E%3Cpath d='M.146.146A.5.5 0 01.784.09l.07.057 23 23a.5.5 0 01-.638.765l-.07-.057-23-23a.5.5 0 010-.708z'/%3E%3Cpath d='M23.146.146a.5.5 0 01.765.638l-.057.07-23 23a.5.5 0 01-.765-.638l.057-.07 23-23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.toast-success {
  background-color: var(--color-fresh-mint);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='11' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-6-8h24v24H-6z'/%3E%3Cg stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='%23ffffff' d='M11.001.5l-7.5 7-2.5-2.5'/%3E%3Cpath stroke='%23FFF' d='M-5.5 15.5h23v-23h-23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.toast-info {
  background-color: var(--color-smalt-blue);
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0-1h24v24H0z'/%3E%3Cg fill='%23ffffff' fill-rule='nonzero'%3E%3Cpath d='M11.5 0C5.148 0 0 5.149 0 11.5S5.148 23 11.5 23C17.85 23 23 17.85 23 11.5S17.85 0 11.5 0zm0 1C17.298 1 22 5.701 22 11.5S17.298 22 11.5 22C5.7 22 1 17.3 1 11.5S5.7 1 11.5 1z'/%3E%3Cpath d='M14.5 18a.5.5 0 01.09.992L14.5 19h-6a.5.5 0 01-.09-.992L8.5 18h6z'/%3E%3Cpath d='M11.5 9a.5.5 0 01.492.41L12 9.5V18a.5.5 0 01-.992.09L11 18v-8H9.5a.5.5 0 01-.492-.41L9 9.5a.5.5 0 01.41-.492L9.5 9h2zM11 4.5a1 1 0 100 2 1 1 0 000-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.toast-warning {
  background-color: var(--color-dark-navy);
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0-1h24v24H0z'/%3E%3Cg fill='%23ffffff' fill-rule='nonzero'%3E%3Cpath d='M11.5 7.654a.5.5 0 01.492.41l.008.09v7.652a.5.5 0 01-.992.09l-.008-.09V8.154a.5.5 0 01.5-.5z'/%3E%3Cpath d='M11.053.276l-11 22A.5.5 0 00.5 23h22a.5.5 0 00.447-.724l-11-22a.5.5 0 00-.894 0zm.447 1.342L21.69 22H1.309L11.5 1.618z'/%3E%3Cpath d='M11.5 18a1 1 0 100 2 1 1 0 000-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.button--edit {
  background-color: var(--color-dark-navy);
  border-radius: 3px;
  color: var(--color-white);
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button--add {
  background-color: var(--color-fresh-mint);
  border-radius: 3px;
  color: var(--color-white);
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

::ng-deep .strava,
.strava {
  color: var(--color-strava);
}

.certificate {
  padding: 40px;
  display: grid;
  grid-template-columns: 147px 1fr;
  grid-column-gap: 50px;
  align-items: center;
}
@media (max-width: 768px) {
  .certificate {
    display: block;
  }
}
.certificate > .icon {
  text-align: center;
}
.certificate > .icon ::ng-deep svg {
  width: 100px;
  height: 88px;
}
.certificate div {
  align-self: flex-end;
}
.certificate div h1 {
  font-weight: normal;
  margin-bottom: 15px;
}
.certificate div h3 {
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 20px;
}
.certificate div p {
  flex-grow: 2;
}
.certificate div button:first-of-type {
  margin-right: 10px;
}
.certificate div button.button--download {
  background-color: var(--color-fresh-mint);
  cursor: pointer;
  border: none;
  color: var(--color-white);
  padding: 0 24px;
  font-size: 16px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-galano);
}
.certificate div button.button--download:focus, .certificate div button.button--download:active {
  outline: none;
}
.certificate div button.button--download * {
  color: white;
}

.wrapper {
  width: 100%;
}
.wrapper > .content {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
}
.wrapper .row {
  display: flex;
  flex-flow: row wrap;
}
.wrapper .row > .col-1 {
  flex-basis: calc(8.3333333333% * 1);
}
@media (max-width: 540px) {
  .wrapper .row > .col-1 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-2 {
  flex-basis: calc(8.3333333333% * 2);
}
@media (max-width: 540px) {
  .wrapper .row > .col-2 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-3 {
  flex-basis: calc(8.3333333333% * 3);
}
@media (max-width: 540px) {
  .wrapper .row > .col-3 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-4 {
  flex-basis: calc(8.3333333333% * 4);
}
@media (max-width: 540px) {
  .wrapper .row > .col-4 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-5 {
  flex-basis: calc(8.3333333333% * 5);
}
@media (max-width: 540px) {
  .wrapper .row > .col-5 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-6 {
  flex-basis: calc(8.3333333333% * 6);
}
@media (max-width: 540px) {
  .wrapper .row > .col-6 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-7 {
  flex-basis: calc(8.3333333333% * 7);
}
@media (max-width: 540px) {
  .wrapper .row > .col-7 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-8 {
  flex-basis: calc(8.3333333333% * 8);
}
@media (max-width: 540px) {
  .wrapper .row > .col-8 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-9 {
  flex-basis: calc(8.3333333333% * 9);
}
@media (max-width: 540px) {
  .wrapper .row > .col-9 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-10 {
  flex-basis: calc(8.3333333333% * 10);
}
@media (max-width: 540px) {
  .wrapper .row > .col-10 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-11 {
  flex-basis: calc(8.3333333333% * 11);
}
@media (max-width: 540px) {
  .wrapper .row > .col-11 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-12 {
  flex-basis: calc(8.3333333333% * 12);
}
@media (max-width: 540px) {
  .wrapper .row > .col-12 {
    flex-basis: 100%;
  }
}
.wrapper .row > .col-o-1 {
  margin-left: calc(8.3333333333% * 1);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-1 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-2 {
  margin-left: calc(8.3333333333% * 2);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-2 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-3 {
  margin-left: calc(8.3333333333% * 3);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-3 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-4 {
  margin-left: calc(8.3333333333% * 4);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-4 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-5 {
  margin-left: calc(8.3333333333% * 5);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-5 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-6 {
  margin-left: calc(8.3333333333% * 6);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-6 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-7 {
  margin-left: calc(8.3333333333% * 7);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-7 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-8 {
  margin-left: calc(8.3333333333% * 8);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-8 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-9 {
  margin-left: calc(8.3333333333% * 9);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-9 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-10 {
  margin-left: calc(8.3333333333% * 10);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-10 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-11 {
  margin-left: calc(8.3333333333% * 11);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-11 {
    margin-left: 0;
  }
}
.wrapper .row > .col-o-12 {
  margin-left: calc(8.3333333333% * 12);
}
@media (max-width: 540px) {
  .wrapper .row > .col-o-12 {
    margin-left: 0;
  }
}

.bg-navy {
  background-color: var(--color-dark-navy);
  color: var(--color-white);
}

.bg-mint {
  background-color: var(--color-fresh-mint);
}

.bg-porcellain {
  background-color: var(--color-porcellain);
}

.textbox-icon {
  padding: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 50px 0;
}
.textbox-icon .icon {
  min-width: 30%;
  flex-basis: 30%;
  padding-top: 30px;
  text-align: center;
}
@media (max-width: 520px) {
  .textbox-icon .icon {
    display: none;
  }
}
.textbox-icon .content {
  min-width: 67%;
  width: 67%;
  flex-basis: 67%;
}
@media (max-width: 520px) {
  .textbox-icon .content {
    min-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }
}
.textbox-icon .content h3 {
  margin-bottom: 20px;
}

.d3chart #x-axis text,
.d3chart #y-axis text {
  font-family: var(--font-galano);
  font-size: 12px;
}
.d3chart #x-axis line,
.d3chart #y-axis line {
  stroke: var(--color-alto-gray);
}
.d3chart .bar__group {
  color: var(--color-dark-navy);
}
.d3chart text {
  fill: var(--color-dark-navy);
}
.d3chart .bar {
  fill: var(--color-fresh-mint);
}

.between {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  transform: translateY(50%);
  position: relative;
  z-index: 2;
}
.between.top-50 {
  transform: translateY(-50%);
}
.between__teaser {
  padding: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
@media (max-width: 540px) {
  .between__teaser {
    padding: 20px;
    flex-wrap: wrap;
  }
}
.between__teaser h3 {
  max-width: 60%;
  margin-right: 40px;
}
@media (max-width: 540px) {
  .between__teaser h3 {
    max-width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-right: 0;
  }
}
.between__teaser h3 span {
  font-weight: normal;
}
.between__teaser a {
  margin-top: 0;
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 540px) {
  .between__teaser a {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.explanation {
  padding: 300px 50px 350px;
  position: relative;
}
.explanation__content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}
@media (max-width: 800px) {
  .explanation {
    padding: 200px 50px;
  }
}

.form__item {
  margin: 0 0 25px;
}
.form__item:last-of-type {
  margin-bottom: 0;
}
.form__item input {
  color: var(--color-dark-navy) !important;
}
.form__item .error {
  flex-basis: 100%;
  background-color: var(--color-flamingo-pink);
  padding: 5px;
  color: var(--color-white);
}
.form__item p {
  font-size: 14px;
}
.form__item .hint {
  margin-top: 15px;
  font-size: 12px;
  line-height: 1;
  color: var(--color-smalt-blue);
}
.form__item .hint a {
  text-decoration: underline;
}
.form__item .hint a:hover {
  color: var(--color-dark-navy);
}

.form-element--checkbox {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.form-element--checkbox input[type=checkbox] {
  appearance: none;
  position: relative;
  width: 30px;
  flex-basis: 30px;
  height: 30px;
  display: block;
  margin: 0;
  border: 1px solid var(--color-dark-navy);
}
.form-element--checkbox input[type=checkbox]:checked::before {
  color: var(--color-dark-navy);
  position: absolute;
  width: 30px;
  height: 30px;
  content: "✓";
  font-size: 30px;
  line-height: 1;
  left: 0;
  top: 0;
  text-align: center;
}
.form-element--checkbox label {
  margin-left: 30px;
  flex-grow: 2;
  flex-basis: 50%;
}
.form-element--checkbox p {
  flex-basis: 100%;
}

.navbar__nav {
  flex-grow: 2;
  flex-basis: 100%;
}
@media (min-width: 1024px) {
  .navbar__nav {
    flex-basis: calc(100% - 130px);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
  }
}
.navbar__nav.is-desktop {
  display: none;
}
@media (min-width: 1024px) {
  .navbar__nav.is-desktop {
    display: flex;
  }
}
.navbar__nav.is-mobile {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-dark-navy);
  overflow: hidden;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 20;
  overflow-y: scroll;
}
@media (min-width: 769px) {
  .navbar__nav.is-mobile {
    top: 110px;
    height: calc(100% - 110px);
  }
}
@media (min-width: 1024px) {
  .navbar__nav.is-mobile {
    display: none;
  }
}
.navbar__nav .nav__links {
  color: var(--color-white);
  width: 100%;
}
@media (min-width: 1024px) {
  .navbar__nav .nav__links {
    color: var(--color-dark-navy);
    text-align: right;
    display: flex;
    flex-flow: row wrap;
    width: auto;
  }
}
.navbar__nav .nav__links .link--active {
  font-weight: bold;
}
.navbar__nav a {
  text-decoration: none;
  line-height: 1.5;
  display: block;
  white-space: nowrap;
  margin-top: 0;
}
.navbar__nav a.link--admin {
  background-color: var(--color-strava);
  line-height: 80px;
  padding: 0 30px;
}
@media (min-width: 1024px) {
  .navbar__nav a.link--admin {
    padding: 0;
    background-color: transparent;
    line-height: 1;
  }
  .navbar__nav a.link--admin:hover svg * {
    stroke: var(--color-strava);
  }
}
.navbar__nav .link-group {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 30px;
}
@media (min-width: 1024px) {
  .navbar__nav .link-group {
    margin: 5px 30px;
    height: auto;
    padding: 0;
  }
}
.navbar__nav .link-group a {
  margin: 0;
}
@media (min-width: 1024px) {
  .navbar__nav .link-group a:last-child {
    margin: 0 10px 0 15px;
  }
}
.navbar__nav button {
  outline: 0;
  border: none;
  background: none;
  cursor: pointer;
  margin: 5px 10px;
  padding-top: 0;
}
.navbar__nav button.langswitch {
  font-family: var(--font-galano);
  display: flex;
  align-items: center;
  color: var(--color-dark-navy);
}
.navbar__nav button.langswitch span {
  margin-left: 5px;
}
.navbar__nav.is-mobile .button {
  background-color: var(--color-fresh-mint);
  margin: 20px;
  padding-top: 18px;
  width: calc(100% - 40px);
  text-align: center;
}
.navbar__nav.is-mobile .button:hover {
  background-color: var(--color-white);
}
.navbar__nav.is-mobile .button svg {
  display: none;
}
.navbar__nav.is-mobile .button--invert {
  background-color: var(--color-dark-navy);
  border-color: var(--color-fresh-mint);
}
.navbar__nav.is-mobile .button--invert:hover {
  background-color: var(--color-white);
  color: var(--color-fresh-mint);
}
.navbar__nav.is-mobile .link-group a {
  height: 100%;
  line-height: 80px;
}
.navbar__nav.is-mobile .link-group a:first-child {
  flex-grow: 2;
}
.navbar__nav.is-mobile .link-group a:last-child:not(:first-child) {
  flex-basis: 40px;
  text-align: center;
}
.navbar__nav.is-mobile .langswitch {
  padding: 30px;
  background-color: var(--color-deep-sea-green);
  color: var(--color-white);
  font-size: 16px;
  margin: auto 0 0 0;
}
.navbar__nav.is-mobile .langswitch span {
  margin-left: 10px;
}
@media (max-width: 600px) {
  .navbar__nav .link__loggedOut {
    flex-basis: 100%;
    text-align: right;
  }
}
.navbar__nav .link__loggedOut.link--active {
  font-weight: bold;
}
.navbar__nav.is-desktop .button {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 20px;
  margin-top: 0;
  font-size: 16px;
}
.navbar__nav.is-desktop .button:last-child {
  margin-right: 0;
}

.image-text {
  display: flex;
  margin-top: 160px;
  align-items: center;
}
.image-text__content {
  width: 50%;
  padding: 0 50px;
}
.image-text__image {
  width: 50%;
  text-align: center;
}
.image-text__image img {
  max-width: 100%;
}
@media (max-width: 1200px) {
  .image-text {
    margin-top: 70px;
    flex-direction: column;
  }
  .image-text__content {
    width: 100%;
    padding: 50px 50px 0 50px;
  }
  .image-text__image {
    width: 80%;
    margin: 20px auto;
  }
}