.d3chart {
    #x-axis,
    #y-axis {
        text {
            font-family: var(--font-galano);
            font-size: 12px;
        }

        line {
            stroke: var(--color-alto-gray);
        }
    }

    .bar__group {
        color: var(--color-dark-navy);
    }

    text {
        fill: var(--color-dark-navy);
    }

    .bar {
        fill: var(--color-fresh-mint);
    }
}
