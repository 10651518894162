.explanation {
    padding: 300px 50px 350px;
    position: relative;

    &__content {
        position: relative;
        max-width: 800px;
        margin: 0 auto;
    }

    @media (max-width: 800px) {
        padding: 200px 50px;
    }
}
