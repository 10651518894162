.between {
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;
    transform: translateY(50%);
    position: relative;
    z-index: 2;

    &.top-50 {
        transform: translateY(-50%);
    }

    &__teaser {
        padding: 50px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;

        @media (max-width: 540px) {
            padding: 20px;
            flex-wrap: wrap;
        }

        h3 {
            max-width: 60%;
            margin-right: 40px;

            @media (max-width: 540px) {
                max-width: 100%;
                text-align: center;
                margin-bottom: 30px;
                margin-right: 0;
            }

            span {
                font-weight: normal;
            }
        }

        a {
            margin-top: 0;
            padding-left: 50px;
            padding-right: 50px;

            @media (max-width: 540px) {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
}
