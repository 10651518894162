:root {
    --color-dark-navy: #03384d; /* Primary Color 1 */
    --color-smalt-blue: #597489; /* Primary Color Lighter */
    --color-fresh-mint: #5bddb8; /* Secondary Color 1 */
    --color-deep-sea-green: #0d4c5b; /* Secondary Color Darker */
    --color-flamingo-pink: #e3177c; /* Highlight Color 2 */
    --color-mauvelous: #f080b7; /* Highlight Color Lighter */
    --color-black: #000000; /* Neutral Black */
    --color-regent-gray: #929aa0; /* Neutral Black Lighter 1 */
    --color-white: #ffffff; /* Neutral White */
    --color-ultra-light: #f8f8f8; /* Neutral White Darker 1 */
    --color-porcellain: #f2f5f6; /* Neutral White Darker 2 */
    --color-loblolly: #c8d1d7; /* Neutral White Darker 3 */
    --color-alto-gray: #d8d8d8; /* Neutral White Darker 4 */
    --color-geyser: #e1e8e9; /* Neutral White Darker 5 */
    --color-athens-gray: #e5eaed; /* Neutral White Darker 6 */
    --color-strava: #fc4c02; /* Semantic Orange 1 */

    --font-galano: 'Galano Grotesque', sans-serif; /* Font HL */
    --font-galano-alt: 'Galano Grotesque Alt', sans-serif; /* Font Text */
}
