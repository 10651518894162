.image-text {
    display: flex;
    margin-top: 160px;
    align-items: center;

    &__content {
        width: 50%;
        padding: 0 50px;
    }

    &__image {
        width: 50%;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    @media (max-width: 1200px) {
        margin-top: 70px;
        flex-direction: column;

        &__content {
            width: 100%;
            padding: 50px 50px 0 50px;
        }

        &__image {
            width: 80%;
            margin: 20px auto;
        }
    }
}
