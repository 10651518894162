@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 400;
    src: local('Galano Grotesque'), url('/assets/fonts/Galano-Grotesque.woff2') format('woff2');
    unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8,
        U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137,
        U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192,
        U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014,
        U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117,
        U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
@font-face {
    font-family: 'Galano Grotesque';
    font-style: normal;
    font-weight: 700;
    src: local('Galano Grotesque Bold'), url('/assets/fonts/Galano-Grotesque-Bold.woff2') format('woff2');
    unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8,
        U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137,
        U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192,
        U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014,
        U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117,
        U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
@font-face {
    font-family: 'Galano Grotesque Alt';
    font-style: normal;
    font-weight: 400;
    src: local('Galano Grotesque Alt'), url('/assets/fonts/Galano-Grotesque-Alt.woff2') format('woff2');
    unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8,
        U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137,
        U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192,
        U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014,
        U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117,
        U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}
@font-face {
    font-family: 'Galano Grotesque Alt';
    font-style: normal;
    font-weight: 700;
    src: local('Galano Grotesque Alt Bold'),
        url('/assets/fonts/Galano-Grotesque-Alt-Bold.woff2') format('woff2');
    unicode-range: U+0020-007E, U+00A1-00A3, U+00A5, U+00A7-00AB, U+00AE-00B1, U+00B4, U+00B6-00B8,
        U+00BA-0107, U+010A-0113, U+0116-011B, U+011E-0123, U+0126-0127, U+012A-012B, U+012E-0133, U+0136-0137,
        U+0139-0148, U+014A-014D, U+0150-015B, U+015E-0161, U+0163-0167, U+016A-016B, U+016E-017E, U+0192,
        U+01FC-01FD, U+0218-021B, U+02C6-02C7, U+02D8-02DD, U+0326, U+1E80-1E85, U+1EF2-1EF3, U+2013-2014,
        U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2116-2117,
        U+2122, U+215B-215E, U+2190-2193, U+2196-2199, U+2212, U+2248, U+2260, U+2264-2265, U+FB01-FB02;
}

:root {
    --font-galano: 'Galano Grotesque', sans-serif;
    --font-galano-alt: 'Galano Grotesque Alt', sans-serif;
}
