html,
body {
    height: 100%;
}

body {
    font-family: var(--font-galano);
    font-size: 16px;
    line-height: 26px;
    color: var(--color-dark-navy);
    background-color: var(--color-white);
    margin: 0;
    padding: 0;

    &.modal-open {
        height: 100vh;
        overflow-y: hidden;
    }
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
}

.button {
    font-family: var(--font-galano);
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
    text-decoration: none;
    padding: 18px 30px;
    background-color: var(--color-fresh-mint);
    color: var(--color-white);
    cursor: pointer;
    font-weight: bold;
    margin-top: 50px;
    outline: 0;
    transition: background-color 0.3s ease-in-out;
    border: 5px solid var(--color-fresh-mint);

    &:hover {
        color: var(--color-fresh-mint);
        background-color: var(--color-white);
    }

    &--invert {
        background-color: var(--color-dark-navy);
        border-color: var(--color-dark-navy);

        &:hover {
            color: var(--color-dark-navy);
            background-color: var(--color-white);
        }
    }

    &--strava {
        background-color: var(--color-strava);
        border-color: var(--color-strava);

        &:hover {
            color: var(--color-strava);
            background-color: var(--color-white);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &--text {
        background: transparent;
        color: var(--color-dark-navy);
        text-decoration: underline;
        outline: 0;
        border: none;
        margin-top: 50px;
        font-family: var(--font-galano);
        cursor: pointer;

        &:hover {
            color: var(--color-flamingo-pink);
        }
    }

    &--circle {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        transform: rotate(16deg);
        background-color: var(--color-strava);
        color: var(--color-white);
        font-size: 26px;
        line-height: 1.3;
        text-decoration: none;
        font-weight: bold;
        display: flex;
        align-items: center;
        text-align: center;
        border: 5px solid var(--color-strava);
        transition: all 0.3s ease-in-out;

        &:hover {
            color: var(--color-strava);
            background-color: var(--color-white);
            transform: rotate(0deg);
        }

        @media (max-width: 769px) {
            transform: rotate(-16deg);
            right: 150px;
            width: 150px;
            height: 150px;
            font-size: 20px;
        }
    }
}

.btn--connectStrava {
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    margin-left: -2px;
    font-family: var(--font-galano);
    color: var(--color-dark-navy);
    font-size: 16px;

    img {
        vertical-align: middle;
    }
}

textarea,
input {
    font-size: 14px;
    line-height: 1.2;
    font-family: var(--font-galano-alt);

    &.ng-invalid.ng-dirty {
        border-color: var(--color-flamingo-pink) !important;

        & + small {
            color: var(--color-flamingo-pink) !important;
        }
    }
}

input[type='text'],
input[type='password'],
textarea {
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    min-height: 45px;
    line-height: 1;
    color: var(--color-dark-navy);
    font-weight: bold;
    padding: 0 12px;
    outline: 0;
    width: 100%;
    margin: 0 auto;

    &:focus {
        border-color: var(--color-fresh-mint);
    }
}

textarea {
    padding: 12px;
    height: 150px;
    line-height: 1.5;
}

a {
    color: inherit;
}

app-landing-page {
    background: var(--color-white);
    display: block;
}

.whitebox {
    background-color: var(--color-white);
    box-shadow: var(--shadow-level-2);
}

.whitebox-padding {
    padding: 80px 80px 40px !important;

    @media (max-width: 768px) {
        padding: 80px 40px 40px !important;
    }
}

.mt50 {
    margin-top: 50px;
}

.toast-top-right {
    top: 120px;
    right: 12px;
}

.toast-error {
    background-color: var(--color-flamingo-pink);
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cg fill='%23ffffff' fill-rule='nonzero'%3E%3Cpath d='M.146.146A.5.5 0 01.784.09l.07.057 23 23a.5.5 0 01-.638.765l-.07-.057-23-23a.5.5 0 010-.708z'/%3E%3Cpath d='M23.146.146a.5.5 0 01.765.638l-.057.07-23 23a.5.5 0 01-.765-.638l.057-.07 23-23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.toast-success {
    background-color: var(--color-fresh-mint);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='11' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-6-8h24v24H-6z'/%3E%3Cg stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='%23ffffff' d='M11.001.5l-7.5 7-2.5-2.5'/%3E%3Cpath stroke='%23FFF' d='M-5.5 15.5h23v-23h-23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.toast-info {
    background-color: var(--color-smalt-blue);
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0-1h24v24H0z'/%3E%3Cg fill='%23ffffff' fill-rule='nonzero'%3E%3Cpath d='M11.5 0C5.148 0 0 5.149 0 11.5S5.148 23 11.5 23C17.85 23 23 17.85 23 11.5S17.85 0 11.5 0zm0 1C17.298 1 22 5.701 22 11.5S17.298 22 11.5 22C5.7 22 1 17.3 1 11.5S5.7 1 11.5 1z'/%3E%3Cpath d='M14.5 18a.5.5 0 01.09.992L14.5 19h-6a.5.5 0 01-.09-.992L8.5 18h6z'/%3E%3Cpath d='M11.5 9a.5.5 0 01.492.41L12 9.5V18a.5.5 0 01-.992.09L11 18v-8H9.5a.5.5 0 01-.492-.41L9 9.5a.5.5 0 01.41-.492L9.5 9h2zM11 4.5a1 1 0 100 2 1 1 0 000-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.toast-warning {
    background-color: var(--color-dark-navy);
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0-1h24v24H0z'/%3E%3Cg fill='%23ffffff' fill-rule='nonzero'%3E%3Cpath d='M11.5 7.654a.5.5 0 01.492.41l.008.09v7.652a.5.5 0 01-.992.09l-.008-.09V8.154a.5.5 0 01.5-.5z'/%3E%3Cpath d='M11.053.276l-11 22A.5.5 0 00.5 23h22a.5.5 0 00.447-.724l-11-22a.5.5 0 00-.894 0zm.447 1.342L21.69 22H1.309L11.5 1.618z'/%3E%3Cpath d='M11.5 18a1 1 0 100 2 1 1 0 000-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.button--edit {
    background-color: var(--color-dark-navy);
    border-radius: 3px;
    color: var(--color-white);
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.button--add {
    background-color: var(--color-fresh-mint);
    border-radius: 3px;
    color: var(--color-white);
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

::ng-deep .strava,
.strava {
    color: var(--color-strava);
}

.certificate {
    padding: 40px;
    display: grid;
    grid-template-columns: 147px 1fr;
    grid-column-gap: 50px;
    align-items: center;

    @media (max-width: 768px) {
        display: block;
    }

    > .icon {
        text-align: center;

        ::ng-deep svg {
            width: 100px;
            height: 88px;
        }
    }

    div {
        align-self: flex-end;

        h1 {
            font-weight: normal;
            margin-bottom: 15px;
        }

        h3 {
            font-weight: normal;
            font-size: 28px;
            margin-bottom: 20px;
        }

        p {
            flex-grow: 2;
        }

        button {
            &:first-of-type {
                margin-right: 10px;
            }

            &.button--download {
                background-color: var(--color-fresh-mint);
                cursor: pointer;
                border: none;
                color: var(--color-white);
                padding: 0 24px;
                font-size: 16px;
                height: 50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-family: var(--font-galano);

                &:focus,
                &:active {
                    outline: none;
                }

                * {
                    color: white;
                }
            }
        }
    }
}

.wrapper {
    width: 100%;

    > .content {
        width: 80%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .row {
        display: flex;
        flex-flow: row wrap;

        @for $i from 1 through 12 {
            > .col-#{$i} {
                flex-basis: calc(100% / 12 * #{$i});

                @media (max-width: 540px) {
                    flex-basis: 100%;
                }
            }
        }

        @for $i from 1 through 12 {
            > .col-o-#{$i} {
                margin-left: calc(100% / 12 * #{$i});

                @media (max-width: 540px) {
                    margin-left: 0;
                }
            }
        }
    }
}

.bg-navy {
    background-color: var(--color-dark-navy);
    color: var(--color-white);
}

.bg-mint {
    background-color: var(--color-fresh-mint);
}

.bg-porcellain {
    background-color: var(--color-porcellain);
}
