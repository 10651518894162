.form__item {
    margin: 0 0 25px;

    &:last-of-type {
        margin-bottom: 0;
    }

    input {
        color: var(--color-dark-navy) !important;
    }

    .error {
        flex-basis: 100%;
        background-color: var(--color-flamingo-pink);
        padding: 5px;
        color: var(--color-white);
    }

    p {
        font-size: 14px;
    }

    .hint {
        margin-top: 15px;
        font-size: 12px;
        line-height: 1;
        color: var(--color-smalt-blue);

        a {
            text-decoration: underline;

            &:hover {
                color: var(--color-dark-navy);
            }
        }
    }
}

.form-element--checkbox {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    input[type='checkbox'] {
        appearance: none;
        position: relative;
        width: 30px;
        flex-basis: 30px;
        height: 30px;
        display: block;
        margin: 0;
        border: 1px solid var(--color-dark-navy);

        &:checked {
            &::before {
                color: var(--color-dark-navy);
                position: absolute;
                width: 30px;
                height: 30px;
                content: '✓';
                font-size: 30px;
                line-height: 1;
                left: 0;
                top: 0;
                text-align: center;
            }
        }
    }

    label {
        margin-left: 30px;
        flex-grow: 2;
        flex-basis: 50%;
    }

    p {
        flex-basis: 100%;
    }
}
